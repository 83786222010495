const settings = {
  siteName: 'The Drew Barrymore Show',
  brand: 'drewbarrymore',
  twitterSiteId: 1156284567394738176,
  defaultKeywords: 'The Drew Barrymore Show, Drew Barrymore, talk show, celebrity guests, entertainment, recipes, lifestyle, good news, inspiration',
  defaultDesc: '"The Drew Barrymore Show" is optimism TV, bringing information, inspiration, and entertainment to the daytime audience this fall. Drew’s creating a movement to march in the army of optimism with a multi-topic format - from human interest stories to happy news to lifestyle segments and celebrity guests.',
  jsBreakpoints: {
    xs: 1200
  },
  breakpoints: {
    sm: '800px'
  },
  ig: {
    access_token: 'zzY-0dxA5WkSPdsWbS3lKSB_sZM',
    app_id: '667678297214039'
  },
  ad: {
    cmsid: 2289,
    description_url: 'https://www.thedrewbarrymoreshow.com',
    dfpBrand: 'thedrewbarrymoreshow',
    fwBaseUrl: 'https://7f077.v.fwmrm.net/ad/g/1',
    fwBrand: 'thedrewbarrymoreshow',
    fwNetworkId: 520311,
    site: 'ctd'
  },
  typeListingAllowedTypes: ['video', 'article', 'gallery', 'recipe']
};

Object.freeze(settings);

export default settings;